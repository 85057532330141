import React from "react";

import { Row, Spacing, Column } from "../../helpers/layout";
import { css } from "styled-components";
import { Title, ItalicTitle } from "../../typography";
import { Colors } from "../../branding";
import { Hr } from "../Hr";
import { Ul, Li } from "./common";
import { Button } from "../Button";

function sanitizeNumber(number: number) {
  return parseFloat(number.toFixed(0));
}

function DimensionInput(props: {
  value: number | null;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <input
      type="text"
      css={css`
        width: 80px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        padding: 0 10px;
        box-sizing: border-box;
        border: none;
        background: ${Colors.white};
        border: 1px solid ${Colors.black};
      `}
      placeholder="inches"
      value={props.value ? sanitizeNumber(props.value) : ""}
      onChange={(e) => {
        props.onChange(e);
      }}
      onMouseDown={(e) => e.stopPropagation()}
    />
  );
}

export function DimensionsInfo(props: {
  canvasSize: {
    width: number;
    height: number;
  };
  wallSize: {
    width: number;
    height: number;
  };
  wallScaleRatio: number | null;
  onRatioChange: (ratio: number | null) => void;
  onSkip: () => void;
  onContinue: () => void;
}) {
  const { canvasSize, wallSize, wallScaleRatio } = props;
  const inchesPer100Width = wallScaleRatio ? wallScaleRatio * 100 : null;
  const relativeWidth = wallSize.width / canvasSize.width;
  const area = wallSize.width * wallSize.height;
  const smallEl = area < 500 * 700;
  const verySmallEl = area < 380 * 500;
  const superSmallEl = area < 250 * 280;

  const realWidth = inchesPer100Width
    ? relativeWidth * inchesPer100Width
    : null;

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: 2px dashed ${Colors.black};
        background: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <div
        css={css`
          width: 100%;
          max-width: 600px;
          box-sizing: border-box;
          padding: ${Spacing.xl};
          padding-bottom: 80px;
          text-align: center;

          ${smallEl &&
          css`
            h2 {
              font-size: 20px;
            }
            li {
              margin-bottom: ${Spacing.m};
              font-size: 12px;
            }
          `}

          ${verySmallEl &&
          css`
            padding: ${Spacing.m};

            h2 {
              font-size: 14px;
            }

            li {
              line-height: 1.3;
              padding-left: 32px;
              margin-bottom: ${Spacing.m};
              font-size: 10px;

              &:before {
                font-size: 16px;
              }
            }
          `}
        `}
      >
        <Column
          gutter={verySmallEl ? Spacing.s : smallEl ? Spacing.m : Spacing.xl}
        >
          <Title>Specify Wall Dimensions</Title>
          {!smallEl && (
            <ItalicTitle>
              *Skip this step if you prefer to view the artwork in your space
              without size and price information.
            </ItalicTitle>
          )}
          <Hr size="large" align="center" color={Colors.black} />
          {!superSmallEl && (
            <Ul>
              <Li>
                Click on the arrow in the bottom right-hand corner to expand
                this box and cover the wall or section of the area where you’re
                considering hanging your artwork
              </Li>
              <Li>
                Enter <i>the width</i> of the area covered by the box in step
                one and we'll automatically calculate the height
              </Li>
            </Ul>
          )}
          {!verySmallEl && (
            <Row gutter={Spacing.xl} justify="center" align="center">
              <div>
                <Button type="button" label="Skip" onClick={props.onSkip} />
              </div>
              <div>
                <Button
                  type="button"
                  label="Continue"
                  onClick={props.onContinue}
                  disabled={!wallScaleRatio}
                />
              </div>
            </Row>
          )}
        </Column>
      </div>
      <div
        css={css`
          position: absolute;
          bottom: 30px;
          left: 0;
          width: 100%;
          display: flex;
          justify-content: center;

          &::before,
          &::after {
            position: absolute;
            width: calc(50% - 50px);
            height: 1px;
            bottom: 0;
            top: 0;
            margin: auto;
            background: ${Colors.black};
            content: "";
          }
          &::before {
            left: 10px;
          }
          &::after {
            right: 10px;
          }
        `}
      >
        <div
          css={css`
            &::before,
            &::after {
              position: absolute;
              width: 1px;
              height: 10px;
              top: 0;
              bottom: 0;
              margin: auto;
              background: ${Colors.black};
              content: "";
            }

            &::before {
              left: 10px;
            }

            &::after {
              right: 10px;
            }
          `}
        ></div>
        <DimensionInput
          value={realWidth}
          onChange={(e) => {
            const realWidth = parseFloat(e.target.value);
            const wallRatio = realWidth / relativeWidth / 100;

            props.onRatioChange(wallRatio || null);
          }}
        />
      </div>
    </div>
  );
}
