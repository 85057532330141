import { maybeAuthorised } from "../request";
import { InteriorDesigner } from "../types/InteriorDesigner";

export async function uploadInteriorDesignerImage(
  token: string,
  data: {
    file: File;
  }
) {
  const API = maybeAuthorised();
  const formData = new FormData();

  formData.append("file", data.file);

  return await API.request<InteriorDesigner>({
    method: "PUT",
    url: `/api/frontend/interior-designer/${token}/upload-image/`,
    data: formData
  });
}
