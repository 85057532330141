const MailIcon = () => (
  <svg width="23px" height="14px" viewBox="0 0 23 14" version="1.1">
    <g transform="translate(1 1)">
      <path
        d="M0 0L21 0L21 12L0 12L0 0Z"
        fill="none"
        stroke="#010101"
        strokeWidth="2"
      />
      <path
        d="M9.99338 7L0 0L9.99338 7L20 0"
        transform="translate(1 1)"
        fill="none"
        stroke="#010101"
        strokeWidth="2"
      />
    </g>
  </svg>
);
export default MailIcon;
