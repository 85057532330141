import React, { useEffect, useState, useRef } from "react";
import { useInteriorDesigner } from "../../context/InteriorDesignerContext";
import { Body, Footer } from "./common";
import { Image } from "../Image";
import { Row, Spacing } from "../../helpers/layout";
import { css } from "styled-components";
import { Button } from "../Button";
import { Rnd } from "react-rnd";
import { Colors } from "../../branding";
import { DimensionsInfo } from "./DimensionsInfo";
import { PlainButton } from "../PlainButton";
import PathIcon from "../../icons/PathIcon";
import { useResponsive } from "../../context/ResponsiveContext";
import { useComponentSize } from "../../helpers/useComponentSize";
import { Wrapper } from "../Wrapper";

type CanvasDimensions = {
  wall_x: number;
  wall_y: number;
  wall_width: number;
  wall_height: number;
  wall_scale_ratio: number | null;
};

export function Dimensions(props: {
  onDifferentPhoto: () => void;
  onSkip: () => void;
  onConfigure: (dimensions: CanvasDimensions) => void;
}) {
  const { interiorDesigner } = useInteriorDesigner();
  const { isMobile, isSmallMobile } = useResponsive();
  const ref = useRef(null);
  const canvasSize = useComponentSize(ref);
  const [wallPos, setWallPos] = useState({
    x: 0,
    y: 0,
  });
  const [wallSize, setWallSize] = useState({
    width: 0,
    height: 0,
  });
  const defaultRatio = null;
  const [wallScaleRatio, setWallRatio] = useState<number | null>(
    interiorDesigner.wall_scale_ratio || defaultRatio
  );
  const controlsSize = isSmallMobile ? 171 : 285;

  /**
   * In case there is no size yet in interiordesigner - we set image size
   */
  useEffect(() => {
    if (canvasSize.width === 0 || canvasSize.height === 0) return;

    const { wall_x, wall_y, wall_width, wall_height } = interiorDesigner;

    if (!wall_width || !wall_height) {
      setWallSize({
        width: canvasSize.width * 0.9,
        height: canvasSize.height * 0.9,
      });
    } else {
      setWallSize({
        width: wall_width * canvasSize.width,
        height: wall_height * canvasSize.height,
      });
    }

    if (!wall_x || !wall_y) {
      setWallPos({
        x: canvasSize.width * 0.05,
        y: canvasSize.height * 0.05,
      });
    } else {
      setWallPos({
        x: wall_x * canvasSize.width,
        y: wall_y * canvasSize.height,
      });
    }
  }, [
    canvasSize.width,
    canvasSize.height,
    interiorDesigner.wall_width,
    interiorDesigner.wall_height,
    interiorDesigner.wall_x,
    interiorDesigner.wall_y,
  ]);

  return (
    <React.Fragment>
      <Body>
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            text-align: center;
            background: ${Colors.dark_gray};
            box-sizing: border-box;
            user-select: none;
          `}
        >
          <div
            css={css`
              position: relative;
              width: auto;
              display: inline-block;
              border: 1px solid ${Colors.black};
            `}
          >
            <Image
              ref={ref}
              notResponsive
              file={{
                id: 0,
                upload_name: "",
                file_path: interiorDesigner.image,
                width: 0,
                height: 0,
              }}
              style={{
                display: "block",
                maxWidth: "100%",
                maxHeight: `calc(100vh - ${controlsSize}px)`,
                width: "auto",
                height: "auto",
              }}
              width="large"
            />
            {wallSize.width > 0 && wallSize.height > 0 && (
              <Rnd
                size={{
                  width: wallSize.width,
                  height: wallSize.height,
                }}
                position={{
                  x: wallPos.x,
                  y: wallPos.y,
                }}
                onDragStop={(_e, position) => {
                  setWallPos({ x: position.x, y: position.y });
                }}
                onResizeStop={(_e, _direction, ref) => {
                  setWallSize({
                    width: parseFloat(ref.style.width),
                    height: parseFloat(ref.style.height),
                  });
                }}
                enableResizing={{
                  bottom: false,
                  bottomLeft: false,
                  bottomRight: true,
                  left: false,
                  right: false,
                  top: false,
                  topLeft: false,
                  topRight: false,
                }}
                resizeHandleStyles={{
                  bottomRight: {
                    width: wallSize.width > 180 ? 165 : 80,
                    height: 35,
                    bottom: 0,
                    right: 0,
                  },
                }}
                resizeHandleComponent={{
                  bottomRight: (
                    <div
                      css={css`
                        position: absolute;
                        padding-top: 10px;
                        padding-right: 10px;
                        right: 0;
                        animation-name: flashing;
                        animation-duration: 1s;
                        animation-iteration-count: infinite;
                        pointer-events: none;
                        text-align: right;

                        svg {
                          width: 12px;
                          height: 12px;

                          path {
                            fill: ${Colors.black};
                          }
                        }
                      `}
                    >
                      <div
                        css={css`
                          position: relative;
                          margin-right: 10px;
                          display: inline-block;
                          vertical-align: middle;
                        `}
                      >
                        {wallSize.width > 180
                          ? "Click & Drag to Resize"
                          : "Resize"}
                      </div>
                      <div
                        css={css`
                          display: inline-block;
                          vertical-align: middle;
                        `}
                      >
                        <PathIcon />
                      </div>
                    </div>
                  ),
                }}
              >
                <DimensionsInfo
                  canvasSize={canvasSize}
                  wallSize={wallSize}
                  wallScaleRatio={wallScaleRatio}
                  onRatioChange={(ratio) => {
                    setWallRatio(ratio);
                  }}
                  onSkip={props.onSkip}
                  onContinue={() => {
                    props.onConfigure({
                      wall_width: wallSize.width / canvasSize.width,
                      wall_height: wallSize.height / canvasSize.height,
                      wall_x: wallPos.x / canvasSize.width,
                      wall_y: wallPos.y / canvasSize.height,
                      wall_scale_ratio: wallScaleRatio,
                    });
                  }}
                />
              </Rnd>
            )}
          </div>
        </div>
      </Body>
      <Footer>
        <Wrapper type="header">
          <Row
            css={css`
              width: 100%;
              height: 100px;
              display: flex;
              box-sizing: border-box;
              align-items: center;

              ${isSmallMobile &&
              css`
                height: 55px;
              `}
            `}
            align="center"
            justify="space-between"
            gutter={isSmallMobile ? Spacing.m : Spacing.xl}
          >
            <div
              css={css`
                flex: 0 0 auto;

                ${isSmallMobile &&
                css`
                  font-size: 10px;
                `}
              `}
            >
              <PlainButton
                hoverEffect
                onClick={() => {
                  props.onDifferentPhoto();
                }}
              >
                {isMobile ? "Change Background" : "Upload different background"}
              </PlainButton>
            </div>
            <div
              css={css`
                flex: 0 0 auto;
              `}
            >
              <Row gutter={isSmallMobile ? Spacing.xs : Spacing.m}>
                <Button
                  type="button"
                  label="Skip"
                  onClick={() => {
                    props.onSkip();
                  }}
                />
                <Button
                  type="button"
                  label="Continue"
                  disabled={!wallScaleRatio}
                  onClick={() => {
                    props.onConfigure({
                      wall_width: wallSize.width / canvasSize.width,
                      wall_height: wallSize.height / canvasSize.height,
                      wall_x: wallPos.x / canvasSize.width,
                      wall_y: wallPos.y / canvasSize.height,
                      wall_scale_ratio: wallScaleRatio || defaultRatio,
                    });
                  }}
                />
              </Row>
            </div>
          </Row>
        </Wrapper>
      </Footer>
    </React.Fragment>
  );
}
