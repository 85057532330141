import LinkIcon from "../icons/LinkIcon";
import { PlainButton } from "./PlainButton";
import { useState, useEffect } from "react";
import copy from "clipboard-copy";
import { getFrontendUrl } from "../helpers/utils";
import CheckIcon from "../icons/CheckIcon";

export function ShareByLink(props: { link: string; disabled?: boolean }) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (copied) {
        setCopied(false);
      }
    }, 1500);

    return () => clearTimeout(timer);
  }, [copied]);

  return (
    <PlainButton
      onClick={async () => {
        await copy(getFrontendUrl(props.link));

        setCopied(true);
      }}
      icon={copied ? <CheckIcon /> : <LinkIcon />}
      iconPosition="left"
      disabled={props.disabled}
    >
      {copied ? "Copied!" : "Link"}
    </PlainButton>
  );
}
