import React, { useRef, useState } from "react";
import { useInteriorDesigner } from "../../context/InteriorDesignerContext";
import { Body, Footer } from "./common";
import { ProductSelector } from "./ProductSelector";
import { css } from "styled-components";
import { Image } from "../Image";
import { Colors } from "../../branding";
import { addToInteriorDesigner } from "../../actions/addToInteriorDesigner";
import { PrintConfiguratorModal } from "../../modals/PrintConfiguratorModal";
import { updateInteriorDesignerItem } from "../../actions/updateInteriorDesignerItem";
import { WallDimensions } from "./WallDimensions";
import { CanvasItem } from "./CanvasItem";
import { useComponentSize } from "../../helpers/useComponentSize";
import { useResponsive } from "../../context/ResponsiveContext";
import { InteriorDesignerItem } from "../../types/items/InteriorDesignerItem";
import { useRecentlyViewed } from "../../context/RecentlyViewedContext";
import { addToRecentlyViewed } from "../../actions/addToRecentlyViewed";

export function Canvas(props: {
  onDimensionChange: () => void;
  onDifferentPhoto: () => void;
}) {
  const { interiorDesigner, fetchInteriorDesigner } = useInteriorDesigner();
  const { recentlyViewed, fetchRecentlyViewed } = useRecentlyViewed();
  const { isSmallMobile } = useResponsive();
  const ref = useRef(null);
  const canvasSize = useComponentSize(ref);
  const [configurator, setConfigurator] = useState<InteriorDesignerItem | null>(
    null
  );

  const { wall_scale_ratio, wall_width, wall_height } = interiorDesigner;
  const inchesPer100Width = wall_scale_ratio ? wall_scale_ratio * 100 : null;
  const wallWidthInInch =
    inchesPer100Width && wall_width ? inchesPer100Width * wall_width : null;
  const wallWidthInPx = wall_width ? wall_width * canvasSize.width : null;
  const wallHeightInPx = wall_height ? wall_height * canvasSize.height : null;
  const proportion =
    wallWidthInPx && wallHeightInPx ? wallWidthInPx / wallHeightInPx : null;
  const wallDimensionsSpecified = !!(wallWidthInPx && wallWidthInInch);
  const wallPxPerInch =
    wallWidthInPx && wallWidthInInch ? wallWidthInPx / wallWidthInInch : null;
  const pxPerInch = wallPxPerInch;
  const controlsSize = isSmallMobile ? 203 : 285;

  return (
    <React.Fragment>
      <Body>
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            text-align: center;
            background: ${Colors.dark_gray};
            box-sizing: border-box;
            overflow: hidden;
            user-select: none;
          `}
        >
          <div
            css={css`
              position: relative;
              width: auto;
              display: inline-block;
              border: 1px solid ${Colors.black};
            `}
          >
            <Image
              ref={ref}
              notResponsive
              file={{
                id: 0,
                upload_name: "",
                file_path: interiorDesigner.image,
                width: 0,
                height: 0,
              }}
              style={{
                display: "block",
                maxWidth: "100%",
                maxHeight: `calc(100vh - ${controlsSize}px)`,
                width: "auto",
                height: "auto",
              }}
              width="large"
            />
            {interiorDesigner.items.map((item) => {
              return (
                <CanvasItem
                  key={`canvas-item--${item.id}`}
                  item={item}
                  canvasSize={canvasSize}
                  pxPerInch={pxPerInch}
                  wallDimensionsSpecified={wallDimensionsSpecified}
                  canvasWidth={canvasSize.width}
                  onEdit={(item) => {
                    setConfigurator(item);
                  }}
                />
              );
            })}
          </div>
        </div>
      </Body>
      <Footer>
        <WallDimensions
          proportion={proportion}
          onDimensionChange={() => {
            props.onDimensionChange();
          }}
          onDifferentPhoto={props.onDifferentPhoto}
        />
        <ProductSelector
          realWidth={wallWidthInInch}
          onSelect={async (product) => {
            await addToInteriorDesigner(interiorDesigner.uuid, {
              product: product.id,
              options: [],
            });
            await fetchInteriorDesigner();

            // Add item to recently viewed
            await addToRecentlyViewed(recentlyViewed.uuid, {
              product: product.id,
            });

            await fetchRecentlyViewed();
          }}
        />
      </Footer>
      {configurator && (
        <PrintConfiguratorModal
          visible={true}
          onClose={() => setConfigurator(null)}
          product={configurator.product}
          selected={{
            size: configurator.size,
            options: configurator.options,
          }}
          disableSizeSelection={!wallDimensionsSpecified}
          disablePrices={!wallDimensionsSpecified}
          onConfigure={async (size, options) => {
            await updateInteriorDesignerItem(
              interiorDesigner.uuid,
              configurator.id,
              {
                size,
                options,
                product: configurator.product.id,
              }
            );

            await fetchInteriorDesigner();
          }}
        />
      )}
    </React.Fragment>
  );
}
