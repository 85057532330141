import React, { useState, useEffect } from "react";
import { useWishlist } from "../../context/WishlistContext";
import { useRecentlyViewed } from "../../context/RecentlyViewedContext";
import { Button } from "../Button";
import { css } from "styled-components";
import { Label } from "../../typography";
import { Row, Spacing } from "../../helpers/layout";
import { Scrollbar } from "../Scrollbar";
import { Product } from "../../types/Product";
import { addToCart } from "../../actions/addToCart";
import { useCart } from "../../context/CartContext";
import { useInteriorDesigner } from "../../context/InteriorDesignerContext";
import { useUserState } from "../../context/UserContext";
import { useComparison } from "../../context/ComparisonContext";
import { ProductPreview } from "../ProductPreview";
import { getProductSize } from "../../helpers/product";
import Router from "next/router";
import { Colors } from "../../branding";
import { PlainButton } from "../PlainButton";
import { useResponsive } from "../../context/ResponsiveContext";
import { Wrapper } from "../Wrapper";
import { ProductSelectorModal } from "../../modals/ProductSelectorModal";
import { useModal } from "../../context/ModalContext";
import SearchIcon from "../../icons/SearchIcon";

const DD_PRODUCT_SELECTOR_KEY = "DD_PRODUCT_SELECTOR_KEY";

type SelectorType = "wishlist" | "recently-viewed" | "comparison" | "search";

function getLocalType() {
  if (typeof localStorage === "undefined") return null;

  return localStorage.getItem(DD_PRODUCT_SELECTOR_KEY) as SelectorType;
}

function MobileProductSelector(props: {
  onSelect?: (product: Product) => void;
}) {
  const [show, setShow] = useState(false);
  const { isAuthenticated } = useUserState();

  return (
    <React.Fragment>
      <Button
        type="button"
        label="Add Artwork"
        style="black-border"
        onClick={() => {
          setShow(true);
        }}
      />
      <ProductSelectorModal
        visible={show}
        onClose={() => {
          setShow(false);
        }}
        defaultSection={isAuthenticated ? "wishlist" : "search"}
        sections={
          isAuthenticated
            ? ["recently-viewed", "wishlist", "comparison", "search"]
            : ["recently-viewed", "comparison", "search"]
        }
        onSelect={(product) => {
          if (props.onSelect) {
            props.onSelect(product);
          }
        }}
      />
    </React.Fragment>
  );
}

function DesktopProductSelector(props: {
  onSelect?: (product: Product) => void;
}) {
  const { isAuthenticated } = useUserState();
  const { wishlist } = useWishlist();
  const { recentlyViewed } = useRecentlyViewed();
  const [showSelectorModal, setShowSelectorModal] = useState(false);
  const { comparison } = useComparison();
  const localType = getLocalType();
  const defaultType = isAuthenticated ? "wishlist" : "search";
  const [type, setType] = useState<SelectorType>(localType || defaultType);
  const items =
    type === "wishlist"
      ? wishlist.items
      : type === "recently-viewed"
      ? recentlyViewed.items
      : type === "comparison"
      ? comparison.items
      : [];

  useEffect(() => {
    localStorage.setItem(DD_PRODUCT_SELECTOR_KEY, type);
  }, [type]);

  const title =
    type === "comparison"
      ? "You have not compared any items"
      : type === "wishlist"
      ? "You have no wishlist items"
      : type === "recently-viewed"
      ? "You have no recently viewed items"
      : null;

  return (
    <>
      <Row align="center" justify="space-between" gutter={Spacing.xl}>
        <div
          css={css`
            flex: 0 0 auto;
          `}
        >
          <h4
            css={css`
              font-size: 15px;
              text-align: center;
              letter-spacing: 0.15em;
              line-height: 1.2;
              text-transform: uppercase;
            `}
          >
            Add <br />
            artwork <br />
            from:
          </h4>
        </div>
        <div
          css={css`
            flex: 0 0 430px;
          `}
        >
          <Row align="center">
            <Label bold={type === "recently-viewed"}>
              <PlainButton
                underlined
                hoverEffect
                onClick={() => {
                  setType("recently-viewed");
                }}
              >
                recently viewed
              </PlainButton>
            </Label>
            <Label>/</Label>
            {isAuthenticated && (
              <>
                <Label bold={type === "wishlist"}>
                  <PlainButton
                    underlined
                    hoverEffect
                    onClick={() => {
                      setType("wishlist");
                    }}
                  >
                    wishlist
                  </PlainButton>
                </Label>
                <Label>/</Label>
              </>
            )}
            <Label bold={type === "comparison"}>
              <PlainButton
                underlined
                hoverEffect
                onClick={() => {
                  setType("comparison");
                }}
              >
                comparison
              </PlainButton>
            </Label>
            <Label>
              <PlainButton
                onClick={() => {
                  setShowSelectorModal(true);
                }}
              >
                <SearchIcon />
              </PlainButton>
            </Label>
          </Row>
        </div>
        <div
          css={css`
            flex: 1 0 20%;
          `}
        >
          {items.length > 0 ? (
            <Scrollbar autoHeight onlyArrows arrowsInside>
              <Row align="center">
                {items.map((item, index) => {
                  const size = getProductSize(item.product);

                  return (
                    <div
                      key={`product-selector--${index}`}
                      onClick={() => {
                        if (props.onSelect) {
                          props.onSelect(item.product);
                        }
                      }}
                      css={css`
                        position: relative;
                        cursor: pointer;

                        &:after {
                          position: absolute;
                          width: 100%;
                          height: 100%;
                          top: 0;
                          left: 0;
                          background: rgba(255, 255, 255, 0.8);
                          opacity: 0;
                          transition: opacity 300ms;
                          content: "+";
                          font-size: 28px;
                          color: ${Colors.black};
                          text-align: center;
                          line-height: 70px;
                        }

                        &:hover {
                          &:after {
                            opacity: 1;
                          }
                        }

                        flex: 0 0
                          ${size
                            ? `${(70 * size.width) / size.height}px}`
                            : "auto"};

                        img {
                          height: 70px;
                          max-width: none;
                          width: auto;
                        }
                      `}
                    >
                      <ProductPreview product={item.product} />
                    </div>
                  );
                })}
              </Row>
            </Scrollbar>
          ) : (
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                background: ${Colors.very_light_grey};
                height: 70px;
                font-size: 14px;
                cursor: pointer;
              `}
              onClick={() => {
                setShowSelectorModal(true);
              }}
            >
              <div>
                {title && (
                  <h4
                    css={css`
                      font-weight: 400;
                    `}
                  >
                    {title}
                  </h4>
                )}
                <h3
                  css={css`
                    text-transform: uppercase;
                  `}
                >
                  + Click here to add artwork to your space
                </h3>
              </div>
            </div>
          )}
        </div>
      </Row>
      <ProductSelectorModal
        visible={showSelectorModal}
        onClose={() => {
          setShowSelectorModal(false);
        }}
        defaultSection="search"
        sections={
          isAuthenticated
            ? ["recently-viewed", "wishlist", "comparison", "search"]
            : ["recently-viewed", "comparison", "search"]
        }
        onSelect={(product) => {
          if (props.onSelect) {
            props.onSelect(product);
          }
        }}
      />
    </>
  );
}

export function ProductSelector(props: {
  disabled?: boolean;
  realWidth?: number | null;
  onSelect?: (product: Product) => void;
}) {
  const { interiorDesigner, closeInteriorDesigner } = useInteriorDesigner();
  const { isAuthenticated } = useUserState();
  const { isSmallMobile, isMobile, isTablet } = useResponsive();
  const { fetchCart } = useCart();
  const { showModal } = useModal();

  return (
    <Wrapper type="header">
      <Row
        css={css`
          height: 100px;
          opacity: ${props.disabled ? "0.3" : "1"};
          pointer-events: ${props.disabled ? "none" : "all"};

          ${isSmallMobile &&
          css`
            height: 55px;
          `}
        `}
        align="center"
        justify="space-between"
        gutter={Spacing.xl}
      >
        <div
          css={css`
            flex: 1 0 20%;
          `}
        >
          {isMobile || isTablet ? (
            <MobileProductSelector onSelect={props.onSelect} />
          ) : (
            <DesktopProductSelector onSelect={props.onSelect} />
          )}
        </div>
        <div
          css={css`
            flex: 0 0 auto;
          `}
        >
          <Button
            label="Buy the look"
            type="button"
            style={isAuthenticated ? "default" : "light-grey"}
            onClick={async () => {
              if (isAuthenticated) {
                for (let i = 0; i < interiorDesigner.items.length; i++) {
                  const item = interiorDesigner.items[i];
                  const size = props.realWidth
                    ? item.size
                    : item.product.sizes[0];

                  await addToCart({
                    product: item.product.id,
                    size: size,
                    options: item.options,
                    productType: item.product.type,
                    book_option: null,
                  });
                }

                await fetchCart();

                Router.push("/cart", "/cart");

                closeInteriorDesigner();
              } else {
                showModal("login", {
                  type: "product",
                });
              }
            }}
          />
        </div>
      </Row>
    </Wrapper>
  );
}
