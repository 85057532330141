import { maybeAuthorised } from "../request";
import { InteriorDesigner } from "../types/InteriorDesigner";

export async function configureInteriorDesigner(
  token: string,
  data: {
    wall_x: number | null;
    wall_y: number | null;
    wall_width: number | null;
    wall_height: number | null;
    wall_scale_ratio: number | null;
  }
) {
  const API = maybeAuthorised();

  return await API.request<InteriorDesigner>({
    method: "PUT",
    url: `/api/frontend/interior-designer/${token}/`,
    data
  });
}
