import React, { useState, useEffect, useRef } from "react";
import { useInteriorDesigner } from "../../context/InteriorDesignerContext";

import { Body, Ul, Li } from "./common";
import { notAuthorised } from "../../request";
import { InHome } from "../../types/InHome";
import { getImageUrl } from "../../helpers/getImageUrl";
import { Colors } from "../../branding";
import { css } from "styled-components";
import { Title } from "../../typography";
import { Column, Spacing } from "../../helpers/layout";
import { Button } from "../Button";
import { uploadInteriorDesignerImage } from "../../actions/uploadInteriorDesignerImage";
import { useResponsive } from "../../context/ResponsiveContext";

type SearchQuery = {
  count: number;
  next: string;
  results: InHome[];
};

export function Initial(props: { onSelect: () => void }) {
  const inputEl = useRef<HTMLInputElement>(null);
  const { interiorDesigner } = useInteriorDesigner();
  const [inHomes, setInHomes] = useState<InHome[]>([]);
  const [uploading, setUploading] = useState(false);
  const { isMobile, isSmallMobile, isDesktop } = useResponsive();

  useEffect(() => {
    const getResults = async () => {
      try {
        const request = await notAuthorised.request<SearchQuery>({
          method: "GET",
          url: `/api/frontend/in-homes/`,
        });

        setInHomes(request.data.results);
      } catch (e) {
        console.log(e);
      }
    };
    getResults();
  }, []);

  const inHome = inHomes[0];
  const image = inHome ? inHome.image : null;

  return (
    <React.Fragment>
      <Body>
        <div
          css={css`
            width: 100%;
            height: 100%;
            background: ${image
              ? `url(${getImageUrl(image, {
                  width: "large",
                })})`
              : Colors.very_light_grey};
            background-size: cover;
            background-position: center center;
          `}
        >
          <div
            css={css`
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              background: rgba(255, 255, 255, 0.8);
            `}
          >
            <div
              css={css`
                width: 90%;
                max-width: 500px;
                text-align: center;

                ${isMobile &&
                css`
                  h2 {
                    font-size: 25px;
                  }

                  li {
                    font-size: 12px;
                  }
                `}

                ${isSmallMobile &&
                css`
                  li {
                    line-height: 1.3;
                    padding-left: 32px;
                    margin-bottom: ${Spacing.m};

                    &:before {
                      font-size: 16px;
                    }
                  }
                `}
              `}
            >
              <Column justify="center" gutter={Spacing.l}>
                <Title>
                  See How the Artwork
                  <br />
                  Looks in Your Space
                </Title>
                <Ul>
                  <Li>
                    Take a <i>straight on</i>, horizontal photo of your space.
                  </Li>
                  <Li>
                    Insert your favorite pieces, try out different frame types
                    and sizes to see what looks best.
                  </Li>
                </Ul>
                <div>
                  <Button
                    label={
                      !isDesktop
                        ? "Take Or Upload an Image of Your Space"
                        : "Upload a Photo of Your Space"
                    }
                    isSubmitting={uploading}
                    type="button"
                    onClick={() => {
                      if (inputEl.current) {
                        inputEl.current.click();
                      }
                    }}
                  />
                  <input
                    ref={inputEl}
                    type="file"
                    accept="image/*"
                    css={css`
                      display: none;
                    `}
                    name="interior_designer_file"
                    onChange={async (event) => {
                      setUploading(true);

                      if (event.target && event.target.files) {
                        await uploadInteriorDesignerImage(
                          interiorDesigner.uuid,
                          {
                            file: event.target.files[0],
                          }
                        );
                      }

                      setUploading(false);
                      props.onSelect();
                    }}
                  />
                </div>
              </Column>
            </div>
          </div>
        </div>
      </Body>
    </React.Fragment>
  );
}
