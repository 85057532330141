import React from "react";
import { css } from "styled-components";
import { Spacing, Row } from "../../helpers/layout";
import { Colors } from "../../branding";
import { useInteriorDesigner } from "../../context/InteriorDesignerContext";
import WheelIcon from "../../icons/WheelIcon";
import { PlainButton } from "../PlainButton";
import { useWrapper } from "../../helpers/useWrapper";
import { useResponsive } from "../../context/ResponsiveContext";

function sanitizeNumber(number: number) {
  return parseFloat(number.toFixed(0));
}

export function WallDimensions(props: {
  proportion: number | null;
  onDimensionChange: () => void;
  onDifferentPhoto: () => void;
}) {
  const spacing = useWrapper("header");
  const { isMobile, isSmallMobile } = useResponsive();
  const { interiorDesigner } = useInteriorDesigner();
  const wallScaleRatio = interiorDesigner.wall_scale_ratio;
  const inchesPer100Width = wallScaleRatio ? wallScaleRatio * 100 : null;

  const relativeWidth = interiorDesigner.wall_width;

  const realWidth =
    inchesPer100Width && relativeWidth
      ? relativeWidth * inchesPer100Width
      : null;

  return (
    <div
      css={css`
        padding: ${Spacing.m} ${spacing};
        background: ${Colors.light_grey};

        ${isSmallMobile &&
        css`
          padding: ${Spacing.s} ${spacing};
          font-size: 9px;
          letter-spacing: 0.5em;
        `}
      `}
    >
      <Row justify="space-between" align="center">
        <div>
          {realWidth ? (
            <PlainButton
              onClick={() => {
                props.onDimensionChange();
              }}
              icon={<WheelIcon />}
              iconPosition="left"
              hoverEffect
            >
              Wall Width:{" "}
              <strong>
                {sanitizeNumber(realWidth)} {isMobile ? '"' : "inches"}
              </strong>
            </PlainButton>
          ) : (
            <PlainButton
              onClick={() => {
                props.onDimensionChange();
              }}
              icon={<WheelIcon />}
              iconPosition="left"
              hoverEffect
            >
              Make To-Scale & See Pricing
            </PlainButton>
          )}
        </div>

        <div>
          <PlainButton
            onClick={() => {
              props.onDifferentPhoto();
            }}
            hoverEffect
          >
            {isMobile ? "Change Background" : "Choose a different background"}
          </PlainButton>
        </div>
      </Row>
    </div>
  );
}
