const LinkIcon = () => (
  <svg width="17px" height="18px" viewBox="0 0 17 18" version="1.1">
    <g transform="translate(1 1)">
      <path
        d="M0 6L6 -2.64627e-07"
        transform="translate(5 4.5)"
        fill="none"
        stroke="#000000"
        strokeWidth="1.5"
      />
      <path
        d="M3.38692 0L1.15762 2.47701C-0.385873 4.20223 -0.385873 6.98853 1.15762 8.71376C2.71032 10.4287 5.21799 10.4287 6.77069 8.71376L9 6.23675"
        transform="translate(0 6)"
        fill="none"
        stroke="#000000"
        strokeWidth="2"
      />
      <path
        d="M5.61308 10L7.84238 7.52299C9.38587 5.79777 9.38587 3.01147 7.84238 1.28624C6.28968 -0.428747 3.78201 -0.428747 2.22931 1.28624L0 3.76325"
        transform="translate(6 0)"
        fill="none"
        stroke="#000000"
        strokeWidth="2"
      />
    </g>
  </svg>
);
export default LinkIcon;
