import MailIcon from "../icons/MailIcon";
import { Link } from "./Link";
import { Spacing } from "../helpers/layout";
import { css } from "styled-components";

export function ShareByEmail(props: {
  subject: string;
  message: string;
  disabled?: boolean;
}) {
  const url = `mailto:?subject=${props.subject}&body=${props.message}`;

  return (
    <Link disable={props.disabled} pure href={url} noEffect>
      <span
        css={css`
          display: inline-block;
          vertical-align: middle;
          margin-right: ${Spacing.s};
        `}
      >
        <MailIcon />
      </span>
      <span
        css={css`
          display: inline-block;
          vertical-align: middle;
        `}
      >
        Email
      </span>
    </Link>
  );
}
