import React, { useState, useEffect } from "react";
import { useInteriorDesigner } from "../../context/InteriorDesignerContext";
import { Header, Container } from "./common";
import { Initial } from "./Initial";
import { Dimensions } from "./Dimensions";
import { Canvas } from "./Canvas";
import { Title } from "../../typography";
import * as types from "../../types/InteriorDesigner";
import { configureInteriorDesigner } from "../../actions/configureInteriorDesigner";
import { Row, Spacing, Column } from "../../helpers/layout";
import CloseIcon from "../../icons/CloseIcon";
import { css } from "styled-components";
import { ShareByEmail } from "../ShareByEmail";
import { ShareByLink } from "../ShareByLink";
import { PlainButton } from "../PlainButton";
import { getFrontendUrl } from "../../helpers/utils";
import { useUserState } from "../../context/UserContext";
import InfoIcon from "../../icons/InfoIcon";
import { useModal } from "../../context/ModalContext";
import { useResponsive } from "../../context/ResponsiveContext";
import { MobileDropdown } from "../MobileDropdown";
import { Hr } from "../Hr";
import ShareIcon from "../../icons/ShareIcon";
import { hotjarTrigger, hotjarVPV } from "../../helpers/analytics";
import { SMALL_MOBILE } from "../../branding";

declare global {
  interface Window {
    olark: any;
  }
}

function getInitialStep(interiorDesigner: types.InteriorDesigner) {
  const { image } = interiorDesigner;

  if (!image) {
    return "initial";
  }

  return "canvas";
}

export function InteriorDesigner(props: { onClose?: () => void }) {
  const { interiorDesigner, fetchInteriorDesigner } = useInteriorDesigner();
  const [step, setStep] = useState<"initial" | "dimensions" | "canvas" | null>(
    null
  );
  const { isMobile } = useResponsive();
  const { showModal, setModalCallback } = useModal();
  const { isAuthenticated } = useUserState();

  const emptyDimensions = {
    wall_width: null,
    wall_height: null,
    wall_x: null,
    wall_y: null,
    wall_scale_ratio: null,
  };

  useEffect(() => {
    if (step || !interiorDesigner.uuid) return;

    const initialStep = getInitialStep(interiorDesigner);

    setStep(initialStep);
  }, [interiorDesigner]);

  useEffect(() => {
    const title = document.title;

    document.title = "Curate Your Space";

    hotjarTrigger("curate_your_space");
    hotjarVPV("/curate-your-space");

    return () => {
      document.title = title;
    };
  }, []);

  const shareByEmail = (
    <ShareByEmail
      subject="My Dream Wall. What Do You Think?"
      message={`I wanted to share a design I created featuring limited edition photography by Drew Doggett. What do you think?\n\n${getFrontendUrl(
        `/curate-your-space/${interiorDesigner.uuid}`
      )}.
  `}
    />
  );

  const shareByLink = (
    <ShareByLink link={`/curate-your-space/${interiorDesigner.uuid}`} />
  );

  const needHelp = (
    <PlainButton
      // onClick={() => {
      //   if (typeof window.olark === "undefined") return;
      //   window.olark("api.box.expand");
      // }}
      icon={<InfoIcon />}
      iconPosition="left"
    >
      <a
        href="mailto:gallery@drewdoggett.com?subject=Curate Your Space Help"
        css={css`
          color: #000000;
          text-decoration: none;
        `}
      >
        Need Help?
      </a>
    </PlainButton>
  );

  return (
    <>
      <Container>
        <Header>
          <Row
            css={css`
              width: 100%;
            `}
            justify="space-between"
            align="center"
          >
            <Title
              css={css`
                @media screen and (max-width: ${SMALL_MOBILE + 1}px) {
                  font-size: 18px;
                }
              `}
            >
              Curate Your Space
            </Title>

            <div
              css={css`
                flex: 0 0 auto;
              `}
            >
              {isMobile ? (
                <Row align="center" gutter={Spacing.xl}>
                  <MobileDropdown icon={<ShareIcon />} title="Options:">
                    {() => (
                      <Column>
                        <div
                          css={css`
                            text-align: center;
                          `}
                        >
                          {shareByEmail}
                        </div>
                        <Hr size="full" />
                        {shareByLink}
                        <Hr size="full" />
                        {needHelp}
                      </Column>
                    )}
                  </MobileDropdown>
                  {props.onClose && (
                    <PlainButton
                      onClick={() => props.onClose!()}
                      icon={<CloseIcon />}
                    />
                  )}
                </Row>
              ) : (
                <Row
                  align="center"
                  gutter={Spacing.xxl}
                  justify="space-between"
                >
                  <Row align="center">
                    <span
                      css={css`
                        margin-right: ${Spacing.m};
                      `}
                    >
                      Share:
                    </span>
                    <Row align="center">
                      {shareByEmail}
                      {shareByLink}
                    </Row>
                  </Row>
                  <div>{needHelp}</div>
                  {props.onClose && (
                    <PlainButton
                      onClick={() => props.onClose!()}
                      icon={<CloseIcon />}
                    />
                  )}
                </Row>
              )}
            </div>
          </Row>
        </Header>
        {step === "initial" && (
          <Initial
            onSelect={async () => {
              await configureInteriorDesigner(
                interiorDesigner.uuid,
                emptyDimensions
              );
              await fetchInteriorDesigner();

              setStep("canvas");
            }}
          />
        )}
        {step === "dimensions" && (
          <Dimensions
            onDifferentPhoto={() => {
              setStep("initial");
            }}
            onSkip={async () => {
              await configureInteriorDesigner(interiorDesigner.uuid, {
                wall_width: 0,
                wall_height: 0,
                wall_x: 0,
                wall_y: 0,
                wall_scale_ratio: 0,
              });
              await fetchInteriorDesigner();

              setStep("canvas");
            }}
            onConfigure={async (dimensions) => {
              if (isAuthenticated) {
                await configureInteriorDesigner(
                  interiorDesigner.uuid,
                  dimensions
                );
                await fetchInteriorDesigner();

                setStep("canvas");
              } else {
                setModalCallback(() => {
                  setStep("canvas");
                });
                showModal("login", {
                  type: "interior-designer",
                });
              }
            }}
          />
        )}
        {step === "canvas" && (
          <Canvas
            onDimensionChange={() => {
              if (isAuthenticated) {
                setStep("dimensions");
              } else {
                setModalCallback(() => {
                  setStep("dimensions");
                });
                showModal("login", {
                  type: "interior-designer",
                });
              }
            }}
            onDifferentPhoto={() => {
              setStep("initial");
            }}
          />
        )}
      </Container>
    </>
  );
}
